import { forwardRef, createElement, SVGProps, ReactSVG, FC } from "react";
import defaultAttributes from "./default-attributes";

type CSS = string | string[] | null | undefined | CSS[];
export interface IconProps extends Partial<SVGProps<SVGSVGElement>> {
  size?: string | number;
  width?: string | number;
  absolute?: boolean;
  css?: CSS;
}

export type IconNode = [
  elementName: keyof ReactSVG,
  attrs: Record<string, string>
][];

export type Icon = FC<IconProps>;

export const toKebabCase = (string: string) =>
  string.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();

const createIcon = (iconName: string, iconNode: IconNode) => {
  const Component = forwardRef<SVGElement, IconProps>(
    (
      {
        color = "currentColor",
        size = 16,
        width: strokeWidth = 1,
        absolute,
        children,
        css = "",
        ...rest
      },
      ref
    ) =>
      createElement(
        "svg",
        {
          ref,
          ...defaultAttributes,
          width: size,
          height: size,
          stroke: color,
          strokeWidth: absolute
            ? (Number(strokeWidth) * 24) / Number(size)
            : strokeWidth,
          className: `lucide lucide-${toKebabCase(iconName)} shrink-0 ${css}`,
          ...rest,
        },
        [
          ...iconNode.map(([tag, attrs]) => createElement(tag, attrs)),
          ...((Array.isArray(children) ? children : [children]) || []),
        ]
      )
  );
  Component.displayName = `${iconName}`;

  return Component;
};

export default createIcon;
