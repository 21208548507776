import {
  ForwardRefExoticComponent,
  PropsWithChildren,
  createElement,
  forwardRef,
} from "react";

import {
  tv as _v,
  type VariantProps,
  type ClassValue as CSS,
  type TV,
} from "tailwind-variants";
import { omit, pick } from "./utils";

export const v: TV = (options, config) =>
  _v(options, {
    ...config,
    twMerge: true,
  });

export const t = (strings: TemplateStringsArray) => {
  return strings[0]!;
};

type AS = keyof JSX.IntrinsicElements | React.ComponentType<any>;

type ElementType1<N extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[N] extends {
    ref?: infer R;
  }
    ? R extends React.Ref<infer E>
      ? E extends HTMLElement
        ? E
        : never
      : never
    : never;

type HTMLElementType<N extends AS> = N extends keyof JSX.IntrinsicElements
  ? ElementType1<N>
  : N extends React.ComponentType<infer P>
  ? P extends {
      ref?: infer R;
    }
    ? R extends React.Ref<infer E>
      ? E extends HTMLElement
        ? E
        : never
      : never
    : never
  : never;

type ElementProps<E extends AS> = E extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[E]
  : E extends React.ComponentType<infer P>
  ? P
  : never;

export type MakeProps<
  V extends (...args: any) => any,
  E extends AS
> = PropsWithChildren<
  VariantProps<V> & {
    css?: CSS;
  } & Omit<ElementProps<E>, keyof VariantProps<V>>
>;

export function makeComponent<
  V extends ((...args: any) => any) & {
    variantKeys: (string | number)[];
  },
  E extends AS,
  Q = MakeProps<V, E>,
  Extra extends {} = {}
>({
  from,
  as,
  name,
  useOverride,
}: {
  from: V;
  as: E;
  name?: string;
  useOverride?: (
    props: MakeProps<V, E> & Extra,
    ref?: React.Ref<HTMLElementType<E>>
  ) => Q;
}): ForwardRefExoticComponent<
  React.PropsWithoutRef<MakeProps<V, E> & Extra> &
    React.RefAttributes<HTMLElementType<E>>
> & {
  as: E;
  from: V;
  variant: <K extends keyof VariantProps<V>>(
    variant: string,
    defaults: Pick<MakeProps<V, E> & Extra, K>
  ) => ForwardRefExoticComponent<
    React.PropsWithoutRef<Omit<MakeProps<V, E> & Extra, K>> &
      React.RefAttributes<HTMLElementType<E>>
  >;
} {
  const Component = forwardRef<HTMLElementType<E>, MakeProps<V, E> & Extra>(
    (initalProps, ref) => {
      const {
        children,
        css,
        className = "",
        ...props
      } = {
        ...initalProps,
        ...useOverride?.(initalProps, ref),
      };
      const variants = pick(props, (from.variantKeys as any) ?? []);
      const rest = omit(props, (from.variantKeys as any) ?? []);

      return createElement(
        as,
        {
          ref,
          ["data-rf"]: name?.toLowerCase(),
          className: from({
            className: [className, css],
            ...variants,
          }),
          ...rest,
        },
        children
      );
    }
  );

  if (name) {
    Component.displayName = name;
  } else if (typeof as === "string") {
    Component.displayName = `Fwd<${as}>`;
  } else {
    Component.displayName = `Fwd<${as.displayName ?? as.name ?? "Unknown"}>`;
  }

  function makeVariant<K extends keyof MakeProps<V, E>>(
    variant: string,
    defaults: Pick<MakeProps<V, E> & Extra, K>
  ) {
    const Variant = forwardRef<
      HTMLElementType<E>,
      Omit<MakeProps<V, E> & Extra, K>
    >((props, ref) => {
      const merged = { ...defaults, ...props } as any;
      merged["data-rf"] = `${name?.toLowerCase()}-${variant?.toLowerCase()}`;

      return <Component {...merged} ref={ref} />;
    });

    Variant.displayName = variant;

    return Object.assign(Variant, {
      as,
      from,
    });
  }

  return Object.assign(Component, {
    as,
    from,
    variant: makeVariant,
  });
}

export function tw<E extends AS>(as: E, base: CSS) {
  return makeComponent({
    from: v({
      base,
    }),
    as,
  });
}

export { VariantProps, CSS };
