import { makeComponent, t, v } from "./tailwind";

const stack = v({
  base: t`flex`,
  variants: {
    direction: {
      x: t`flex-row`,
      y: t`flex-col`,
    },
    debug: {
      true: t`outline outline-red-200 [&_*]:outline [&_*]:outline-red-200`,
      false: t``,
    },
  },

  defaultVariants: {
    direction: "x",
    debug: false,
  },
});

const Stack = makeComponent({
  from: stack,
  as: "div",
  name: "Stack",
});

const X = Stack.variant("X", {
  direction: "x",
});

const Y = Stack.variant("Y", {
  direction: "y",
});


export { stack, Stack, X, Y };
