"use client";

import { Frame } from "./Frame";
import { Loader } from "./Loader";
import { MakeProps, makeComponent, t, v } from "./tailwind";

const button = v({
  base: t`inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-primary-50`,
  variants: {
    variant: {
      default: t`bg-primary-400 text-primary-50 hover:bg-primary-400/90`,
      destructive: t` bg-rose-500 text-rose-50 hover:bg-rose-400/90`,
      outline: t`border border-primary-200/80 hover:bg-primary-400/90 hover:text-primary-50`,
      secondary: t`bg-primary-50 text-primary-500 hover:bg-primary-50/50`,
      ghost: t`hover:bg-primary-50/40 text-neutral-500`,
      link: t`underline-offset-4 hover:underline text-primary-500`,
    },
    size: {
      default: t`h-10 py-2 px-4`,
      xs: t`h-8 px-3 rounded-md text-sm`,
      sm: t`h-9 px-3 rounded-md`,
      lg: t`h-11 px-8 rounded-md`,
    },
    loading: {
      true: t`cursor-progress !text-transparent`,
      false: t``,
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export type ButtonProps = MakeProps<typeof button, "button">;

const Button = makeComponent({
  from: button,
  as: "button",
  name: "Button",
  useOverride({ loading, children, size, css }) {
    if (loading) {
      return {
        disabled: true,
        children: (
          <Frame css="z-0 overflow-visible shrink-0">
            {children}
            <Frame.Layer css="inset-0 justify-center">
              <Loader css="h-auto button-loader grow hug" />
            </Frame.Layer>
          </Frame>
        ),
        css: [t`bg-neutral-200`, css],
      };
    }

    return {};
  },
});

export { Button };
