"use client";

import * as Dialog from "@radix-ui/react-dialog";
import { XIcon } from "@reframe.so/icons/icons/XIcon";
import { t, tw, CSS } from "./tailwind";
import { Y } from "./Stack";

const Overlay = tw(
  Dialog.Overlay,
  t`fixed inset-0 z-50 bg-neutral-500/90 transition-all duration-100 data-[state=closed]:animate-[exit_150ms_cubic-bezier(0.16, 1, 0.3,1)] data-[state=closed]:fade-out data-[state=open]:fade-in`
);

const Content = tw(
  Dialog.Content,
  t`fixed z-50 focus:outline-none bg-white flex flex-col w-full h-full gap-4 rounded-b-lg border s p-6 shadow-lg animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 data-[state=open]:sm:slide-in-from-bottom-0 justify-center`
);

const Portal = tw(Dialog.Portal, t``);

const Root = tw(Dialog.Root, t``);

const Close = tw(
  Dialog.Close,
  t`absolute right-0 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground z-50`
);

const Title = tw(
  Dialog.Title,
  t`text-2xl font-medium leading-none tracking-tight`
);

export const Modal = ({
  children,
  open = false,
  setOpen = () => {},
  title,
  css,
  fullScreen,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  css?: CSS;
  fullScreen?: boolean;
}) => {
  return (
    <Root
      open={open}
      onOpenChange={(bool) => {
        setOpen(bool);
      }}
    >
      <Portal>
        <Y
          css={[
            "fixed inset-0 z-50 flex items-start justify-center sm:items-center",
          ]}
        >
          <Overlay />
          <Content
            css={[
              css,
              "sm:rounded-lg",
              fullScreen
                ? "sm:h-[calc(100vh-48px)] sm:w-[calc(100vw-48px)]"
                : "sm:h-auto sm:max-w-lg sm:zoom-in-90",
            ]}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {title ? (
              <Y css="text-left pb-6 relative">
                <Title>{title}</Title>
                <Close>
                  <XIcon css="w-6 h-6 sm:w-5 sm-h-5" width={2} />
                </Close>
              </Y>
            ) : (
              <Close css={["top-4 right-4", fullScreen ? "" : "sm:hidden"]}>
                <XIcon css="w-6 h-6 sm:w-5 sm-h-5" width={2} />
              </Close>
            )}
            {children}
          </Content>
        </Y>
      </Portal>
    </Root>
  );
};
