import { stack } from "./Stack";
import { makeComponent, t, v } from "./tailwind";

const layer = v({
  extend: stack,
  base: t`absolute data-rf-layer rounded-[inherit]`,
  variants: {},
});

const frame = v({
  extend: stack,
  base: t`relative overflow-hidden [&>*:not(.data-rf-layer)]:z-10`,
  variants: {
    error: {
      false: t``,
      true: t`border !border-red-600/70 focus:border-red-400 focus:ring-red-300`,
    },
  },
  defaultVariants: {},
});

const Layer = makeComponent({
  from: layer,
  as: "div",
  name: "Frame.Layer",
});

const Frame = Object.assign(
  makeComponent({
    from: frame,
    as: "div",
    name: "Frame",
  }),
  { Layer }
);

export { Frame, Layer };
