import { MakeProps, makeComponent, t, v } from "./tailwind";

const text = v({
  base: t`leading-7`,
  variants: {
    variant: {
      h1: t`scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl`,
      h2: t`scroll-m-20 pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0`,
      h3: t`scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0`,
      h4: t`scroll-m-20 text-xl font-semibold tracking-tight`,
      blockquote: t`mt-6 border-l-2 pl-6 italic`,
      code : t`bg-neutral-200/10 text-red-600 px-1 rounded-md`,
    },
  },
  defaultVariants: {},
});

export type TextProps = MakeProps<typeof text, "span">;

const TextBase = makeComponent({
  from: text,
  as: "span",
  name: "Text",
});

const Typography = TextBase;

const Text = Object.assign(TextBase, {
  h1: TextBase.variant("h1", { variant: "h1" }),
  h2: TextBase.variant("h2", { variant: "h2" }),
  h3: TextBase.variant("h3", { variant: "h3" }),
  h4: TextBase.variant("h4", { variant: "h4" }),
  blockquote: TextBase.variant("blockquote", { variant: "blockquote" }),
});

export { Text, Typography };
